import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=ff4e9e16&"
import script from "./Reports.vue?vue&type=script&lang=js&"
export * from "./Reports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/streamlined-business-solutions/sbs-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ff4e9e16')) {
      api.createRecord('ff4e9e16', component.options)
    } else {
      api.reload('ff4e9e16', component.options)
    }
    module.hot.accept("./Reports.vue?vue&type=template&id=ff4e9e16&", function () {
      api.rerender('ff4e9e16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Reports.vue"
export default component.exports